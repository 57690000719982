import ZooxPay from "@zoox-framework/pay";
import PersonService from "./PersonService";
export default class CardService {
  constructor(companyId, token) {
    const personService = new PersonService();
    const personId = personService.getPersonId();
    const accessToken = personService.getPersonToken();
    this.zooxPay = new ZooxPay({
      accessToken,
      personId,
      baseUrl: process.env.VUE_APP_ZOOXPAY_BASE_URI,
      chargeToken: token,
      companyId: companyId
    });
  }

  async createCard(params) {
    return this.zooxPay.createCard(params);
  }

  async createCardToken(params) {
    return this.zooxPay
      .createCardToken(params)
      .then(resp => {
        return resp;
      })
      .catch(err => {
        return err.response;
      });
  }

  async deleteCard(cardId) {
    return this.zooxPay.removeCard(cardId);
  }

  async getCards() {
    const response = await this.zooxPay.listCards();
    return response.data;
  }
}
