import Vue from "vue";
import Vuex from "vuex";
import { SubscriptionService } from "@zoox-framework/messenger-helper";

Vue.use(Vuex);

const cleanState = {
  loading: 0,
  currentCharge: {},
  paymentValue: 0,
  isValidated: false,
  terms: null,
  virtualWallet: true,
  pushNotificationService: new SubscriptionService()
};

const Store = new Vuex.Store({
  state: Object.assign({}, cleanState),
  mutations: {
    "loader/START_LOADING": function(state, payload) {
      if (payload.showLoading) {
        state.loading = state.loading + 1;
      }
    },
    "loader/FINISH_LOADING": function(state, payload) {
      if (payload.showLoading) {
        state.loading = state.loading - 1;
      }
    },
    setCurrentCharge: function(state, payload) {
      state.currentCharge = payload;
    },
    setPaymentValue: function(state, payload) {
      state.paymentValue = payload;
    },
    setIsValidated: function(state, payload) {
      state.isValidated = payload;
    },
    setEnableVirtualWallet: function(state, payload) {
      state.virtualWallet = payload;
    },
    setTerms: function(state, payload) {
      state.terms = payload;
    }
  },
  actions: {
    $setCurrentCharge: function(state, value) {
      state.commit("setCurrentCharge", value);
    },
    $setPaymentValue: function(state, value) {
      state.commit("setPaymentValue", value);
    },
    $setIsValidated: function(state, value) {
      state.commit("setIsValidated", value);
    },
    $setEnableVirtualWallet: function(state, value) {
      state.commit("setEnableVirtualWallet", value);
    },
    $setTerms: function(state, value) {
      state.commit("setTerms", value);
    }
  },
  getters: {
    getTerms(state) {
      return state.terms;
    }
  }
});

window.__zooxStore = Store;

export default Store;
