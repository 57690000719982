import MainMobileLayout from "@/components/shared/Layouts/MainMobile/MainMobile.vue";

const ListCards = () => import("./List/List.vue");
const CreateCards = () => import("./Create/Create.vue");

const CardRoutes = {
  component: MainMobileLayout,
  children: [
    {
      path: "",
      name: "ListCards",
      component: ListCards,
      meta: { requiresAuth: true }
    },
    {
      path: "create",
      name: "CreateCard",
      component: CreateCards,
      meta: { requiresAuth: true },
      props: true
    },
    {
      path: "edit/:id",
      name: "EditCard",
      component: CreateCards,
      meta: { requiresAuth: true }
    },
    { path: "*", redirect: "/card" }
  ]
};

export default CardRoutes;
