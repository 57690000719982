import {
  ApiClient,
  CryptoLocalStorage,
  TokenService
} from "@zoox-framework/zoox-infra";
const moment = require("moment");

export default class PersonService {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async login(data) {
    try {
      const resp = await this.apiClient.post(
        "person/login",
        data,
        null,
        false,
        true
      );
      if (resp && resp.id && resp.token) {
        this.setLoginData(resp);
      }
      return resp;
    } catch (err) {
      return err;
    }
  }

  async tempLogin(personId) {
    const resp = await this.apiClient.post("person/tempLogin", { personId });
    if (resp && resp.id && resp.token) {
      this.setLoginData(resp);
    }
    return resp;
  }

  async facialLogin(image) {
    const resp = await this.apiClient.post("person/facial-login", { image });
    if (resp && resp.id && resp.token) {
      this.setLoginData(resp);
    }

    return resp;
  }

  async register(data) {
    return this.apiClient.post("person/register", data);
  }

  async forgotPassword(data) {
    return this.apiClient.post("person/forgot-password", data);
  }

  async recoverPassword(data) {
    return this.apiClient.post("person/recover-password", data);
  }

  async confirmRegistration(data) {
    return this.apiClient.post("person/confirm-registration", data);
  }

  async resendRegistrationCode(data) {
    return this.apiClient.post("person/recover-confirmation-code", data);
  }

  async get(id) {
    return this.apiClient.get(`person/${id}`);
  }

  async getContactsList(id, name) {
    return this.apiClient.post(`person/${id}/contacts`, {
      id: id,
      name: name
    });
  }

  async update(person, provider = "fnrh") {
    return this.apiClient.post("person/update", { person, provider });
  }

  setLoginData(person) {
    this.setPersonId(person.id);
    this.setPersonName(person.name);
    this.setTokenExpiration(person.tokenExpiration);
    TokenService.saveToken(person.token);
  }

  logout() {
    CryptoLocalStorage.removeItem("person_id");
    TokenService.destroyToken();
  }

  setPersonId(personId = null) {
    CryptoLocalStorage.setItem("person_id", personId);
  }

  getPersonId() {
    return CryptoLocalStorage.getItem("person_id") || null;
  }

  getPersonToken() {
    return TokenService.getToken();
  }

  setPersonName(personName = null) {
    CryptoLocalStorage.setItem("person_name", personName);
  }

  getPersonName() {
    return CryptoLocalStorage.getItem("person_name") || null;
  }

  setTokenExpiration(tokenExpiration = null) {
    CryptoLocalStorage.setItem("token_expiration", tokenExpiration);
  }

  getTokenExpiration() {
    return CryptoLocalStorage.getItem("token_expiration") || null;
  }

  isAuthenticated() {
    const token = this.getPersonToken();
    const tokenExpiration = this.getTokenExpiration();

    if (token != null && tokenExpiration != null) {
      return moment().isBefore(tokenExpiration);
    }

    return false;
  }
}
