import MainMobileLayout from "@/components/shared/Layouts/MainMobile/MainMobile.vue";
import ClientAuthRoutes from "@/components/views/ClientAuth/routes";
import PaymentRoutes from "@/components/views/Payment/routes";
import CardRoutes from "@/components/views/Card/routes";
import UserRoutes from "@/components/views/User/routes";
const UserProfile = () => import("@/components/views/User/Profile.vue");

const routes = [
  {
    path: "/auth",
    ...ClientAuthRoutes
  },
  {
    path: "/payment",
    ...PaymentRoutes
  },
  {
    path: "/card",
    ...CardRoutes
  },
  {
    path: "/user",
    ...UserRoutes
  },
  {
    path: "/",
    component: MainMobileLayout,
    children: [
      {
        path: "complete-register",
        name: "CompleteRegister",
        component: UserProfile,
        meta: { requiresAuth: true }
      },
      {
        path: "",
        name: "PaymentSolicitations",
        redirect: "/auth"
      }
    ]
  },
  {
    path: "*",
    redirect: "/auth",
    meta: { requiresAuth: true }
  }
];

export default routes;
