import { Helper } from "@zoox-framework/zoox-infra";

const queryLang = Helper.getParameterByName("lang");
const storageLang = localStorage.getItem("currentLang");

const browserLang = (navigator.language || navigator.userLanguage || "").split(
  /[-_]+/
)[0];

const availableLangs = ["en", "es", "uk", "pt"];

// Irá retornar na ordem de prioridade a primeira linguagem que for válida
// Ordem de prioridade:
//   QueryParam > LocalStorage > Browser > Inglês padrão
let currentLang = [queryLang, storageLang, browserLang, "en"].find(language =>
  availableLangs.includes(language)
);

localStorage.setItem("currentLang", currentLang);

import Vue from "vue";

import { LanguagesManager } from "@zoox-framework/zoox-infra";

import { Languages } from "@zoox-framework/smck-core";

const languagesManager = new LanguagesManager(
  Vue,
  Languages.PayMobile,
  currentLang
);

export default languagesManager.i18n;
