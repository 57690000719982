import router from "../router";
import Vue from "vue";

export default function HttpErrorHandler(error) {
  if (error) {
    if (error.status) {
      if (error.showException && error.status === 401) {
        if (error.body && error.body.detail) {
          this.$toaster.error(error.body.detail);
        } else {
          this.$toaster.error(Vue.i18n.t("INFRA.HTTP.ACCESS_DENIED_MESSAGE"));
        }
        router.push({ name: "LoginClient" });
      } else if (error.showException && error.status === 403) {
        this.$toaster.error(Vue.i18n.t("INFRA.HTTP.ACCESS_DENIED_MESSAGE"));
        router.push({ name: "LoginClient" });
      } else if (
        error.showException &&
        error.status === 412 &&
        error.body &&
        error.body.detail &&
        error.body.detail.toLowerCase().indexOf("expired") > -1
      ) {
        this.$toaster.error(Vue.i18n.t("INFRA.HTTP.SESSION_EXPIRED_MESSAGE"));
        router.push({ name: "LoginClient" });
      } else if (error.showException && error.status >= 500) {
        this.$toaster.error(window.getMessage(error), error);
      }
    } else {
      if (error.status === 0) {
        return;
      } else {
        this.$toaster.error(window.getMessage(error), error);
        console.error(`${error.operation} failed: ${window.getMessage(error)}`);
      }
    }
  }
}
