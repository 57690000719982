import { ApiClient } from "@zoox-framework/zoox-infra";

export default class TermsService {
  constructor() {
    this.apiClient = new ApiClient();
  }

  getTermByType(type, lang) {
    switch (lang) {
      case "en":
        lang = "en_US";
        break;
      case "uk":
        lang = "en_UK";
        break;
      case "es":
        lang = "es_ES";
        break;
      case "pt":
        lang = "pt_BR";
        break;
      case "fr":
        lang = "fr_FR";
        break;
    }
    return this.apiClient.get(`open/terms-last?type=${type}&language=${lang}`);
  }
}
