import Vue from "vue";
import Router from "vue-router";

import routes from "./routes";
import PersonService from "@/services/PersonService";
import PaymentService from "@/services/PaymentService";
import OrderService from "@/services/OrderService";

Vue.use(Router);

const router = new Router({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  mode: "history",
  base: process.env.BASE_ENV
});

router.beforeEach(async (to, from, next) => {
  if (to.query.chargeToken) {
    const token = to.query.chargeToken;
    if (token) {
      const paymentService = new PaymentService();
      paymentService.setChargeToken(token);
    }
  }
  if (to.meta.requiresAuth) {
    const personService = new PersonService();
    if (personService.isAuthenticated()) {
      const personId = personService.getPersonId();
      const personName = personService.getPersonName();
      const paymentService = new PaymentService();
      const token = paymentService.getChargeToken();
      const currentToken = paymentService.getCurrentChargeToken();

      if (token != null && currentToken != null && token !== currentToken) {
        const orderService = new OrderService();
        await paymentService.updatePersonCharge(personId, token, personName);
        const charge = await orderService.getChargeByToken(token);
        const orderId = charge.order.id;
        const order = await orderService.getOrderById(orderId);
        await orderService.setLocalOrder(order);
      }

      next();
    } else {
      next("auth");
    }
    // } else if (to.path === "/auth" || to.path === "/") {

    //   const personService = new PersonService();
    //   const personId = personService.getPersonId();
    //   const personName = personService.getPersonName();
    //   const paymentService = new PaymentService();
    //   const token = paymentService.getChargeToken();
    //   const currentToken = paymentService.getCurrentChargeToken();
    //   if (token != null && currentToken != null && token !== currentToken) {
    //     next("/payment/solicitations");
    //   }
    //   else{
    //     next();
    //   }
  } else {
    next();
  }
});

export default router;
