export default {
  name: "zPayTermsModal",
  props: {
    type: {
      type: Number,
      default: () => null
    },
    companyId: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    editorConfig: {
      toolbar: [[]],
      allowedContent: true,
      height: "500"
    }
  }),
  computed: {
    terms() {
      return this.$store.getters.getTerms;
    },
    term() {
      if (this.type === 1) {
        return this.terms[0] || { terms: "" };
      } else if (this.type === 2) {
        return this.terms[1] || { terms: "" };
      }
      return { terms: "" };
    }
  }
};
