// @ts-nocheck
import "babel-polyfill";

import Vue from "vue";
import "./plugins/vuetify";
import App from "./App";
import router from "./router";
import lodash from "lodash";
import VueSocketIO from "vue-socket.io";
import io from "socket.io-client";
import VueLodash from "vue-lodash";
import { ApiHttpInterceptor } from "@zoox-framework/zoox-infra";
import Buefy from "buefy";
import VeeValidate from "vee-validate";
import { createToastInterface } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { Main } from "@zoox-framework/smck-core";
import CKEditor from "ckeditor4-vue";
import {
  dateFilter,
  numberFilter,
  DialogPlugin,
  Loading
} from "@zoox-framework/zoox-components";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import i18n from "@/middlewares/i18n";
import core from "@/core/index";
import store from "@/store";
// import "./theme/app.css";

import HttpErrorHandler from "./helpers/http-error-handler";

Vue.filter("date", dateFilter);
Vue.filter("number", numberFilter);
Vue.component("Loading", Loading);

Vue.use(VueLodash, { lodash: lodash });
Vue.use(VeeValidate);
Vue.use(core);
Vue.use(CKEditor);

Vue.config.productionTip = false;

Vue.$globalEvent = new Vue();

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 9000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  icon: true
};

Vue.prototype.$toaster = createToastInterface(options);

Vue.use(Buefy);
Vue.use(DialogPlugin);

Vue.use(
  new VueSocketIO({
    debug: process.env.VUE_APP_DEBUG,
    connection: io(process.env.VUE_APP_BASE_URI, {
      "reconnection delay": 0,
      "reopen delay": 0,
      "force new connection": true,
      transports: ["websocket"]
    })
  })
);

Main(Vue);
Vue.http.interceptors.push(ApiHttpInterceptor);

new Vue({
  i18n,
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
  mounted() {
    Vue.$globalEvent.$on("httpError", HttpErrorHandler);
  }
});
