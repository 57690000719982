const menu = [
  {
    title: "Dashboard",
    group: "Dashboard",
    component: "Dashboard",
    icon: "ti-home",
    items: [
      {
        name: "dashboard1",
        title: "Dashboard1",
        component: "components/dashboard1"
      },
      {
        name: "dashboard2",
        title: "Dashboard2",
        component: "components/dashboard2"
      }
    ]
  },
  {
    group: "widgets",
    title: "Widgets",
    icon: "ti-bolt",

    component: "widgets",
    items: [
      {
        name: "general",
        title: "General",
        component: "components/general"
      },
      {
        name: "charts",
        title: "Charts",
        component: "components/charts"
      }
    ]
  },
  {
    title: "Charts",
    group: "charts",
    component: "charts",
    icon: "ti-bar-chart",
    items: [
      {
        name: "chartjs",
        title: "ChartJs",
        component: "components/chartjs"
      },
      {
        name: "chartist",
        title: "Chartist",
        component: "components/chartist"
      },
      {
        name: "c3",
        title: "C3",
        component: "components/c3"
      },
      {
        name: "echart",
        title: "Echart",
        component: "components/echart"
      },
      {
        name: "apexchart",
        title: "ApexChart",
        component: "components/apexchart"
      },
      {
        name: "sparkline",
        title: "Sparkline",
        component: "components/sparkline"
      },
      {
        name: "morris",
        title: "Morris",
        component: "components/morris"
      }
    ]
  },
  {
    title: "Icons",
    group: "icons",
    component: "icons",
    icon: "ti-gift",
    items: [
      {
        name: "fontawesome",
        component: "components/fontawesome",
        title: "FontAwesome"
      },
      {
        name: "themify",
        component: "components/themify",
        title: "Themify "
      },
      {
        name: "ion",
        component: "components/ion",
        title: " Ion "
      },
      {
        name: "simpleline",
        component: "components/simpleline",
        title: " Simpleline"
      },
      {
        name: "typicons",
        component: "components/typicons",
        title: " Typicons"
      },
      {
        name: "weather",
        component: "components/weather",
        title: " Weather"
      },
      {
        name: "material",
        component: "components/material",
        title: " Material"
      }
    ]
  },
  {
    group: "forms",
    title: "Forms",
    icon: "ti-pencil-alt2",
    component: "forms",

    items: [
      {
        name: "combobox",
        title: "Combobox",
        component: "components/combobox"
      },
      {
        name: "forms",
        title: "Forms",
        component: "components/forms"
      },
      {
        name: "selectioncontrols",
        title: "Selection Controls",
        component: "components/selectioncontrols"
      },
      {
        name: "selects",
        title: "Selects",
        component: "components/selects"
      },
      {
        name: "sliders",
        title: "Sliders",
        component: "components/sliders"
      },
      {
        name: "textarea",
        title: "Text Area",
        component: "components/textarea"
      },
      {
        name: "textfields",
        title: "Text Fields",
        component: "components/textfields"
      }
    ]
  },
  {
    title: "Tables",
    group: "tables",
    icon: "ti-gallery",
    items: [
      {
        name: "table-basic",
        title: "Table Basic",
        component: "components/table-basic"
      },
      {
        name: "datatable",
        title: "Datatable",
        component: "components/datatable"
      }
    ]
  },
  {
    title: "Pickers",
    group: "pickers",
    icon: "ti-calendar",
    component: "pickers",
    items: [
      {
        name: "datePickers",
        title: "DatePickers",
        component: "components/date-pickers"
      },
      {
        name: "timepickers",
        title: "TimePickers",
        component: "components/time-pickers"
      }
    ]
  },
  {
    title: "UI Kits",
    group: "ui",
    component: "ui",
    icon: "ti-server",
    items: [
      {
        name: "alerts",
        title: "Alerts",
        component: "components/alerts"
      },
      {
        name: "avatars",
        title: "Avatars",
        component: "components/avatars"
      },
      {
        name: "badges",
        title: "Badges",
        component: "components/badges"
      },
      {
        name: "buttons",
        title: "Buttons",
        component: "components/buttons"
      },
      {
        name: "bottomsheets",
        title: "Bottomsheets",
        component: "components/Bottomsheets"
      },
      {
        name: "bottomnavigation",
        title: "Bottomnavigation",
        component: "components/Bottomnavigation"
      },
      {
        name: "breadcrumbs",
        title: "Breadcrumbs",
        component: "components/breadcrumbs"
      },
      {
        name: "cards",
        title: "Cards",
        component: "components/cards"
      },
      {
        name: "carousels",
        title: "Carousels",
        component: "components/carousels"
      },
      {
        name: "chips",
        title: "Chips",
        component: "components/chips"
      },
      {
        name: "dialogs",
        title: "Dialogs",
        component: "components/dialogs"
      },
      {
        name: "dataiterator",
        title: "Dataiterator",
        component: "components/dataiterator"
      },
      {
        name: "dividers",
        title: "Dividers",
        component: "components/dividers"
      },
      {
        name: "expansionpanels",
        title: "Expansion Panels",
        component: "components/expansionpanels"
      },
      {
        name: "floatingactionbuttons",
        title: "Fab",
        component: "components/floatingactionbuttons"
      },
      {
        name: "footer",
        title: "Footer",
        component: "components/footer"
      },
      {
        name: "images",
        title: "Images",
        component: "components/imagens"
      },
      {
        name: "lists",
        title: "Lists",
        component: "components/lists"
      },
      {
        name: "menus",
        title: "Menus",
        component: "components/menus"
      },
      {
        name: "navigationdrawers",
        title: "Navigation Drawers",
        component: "components/navigationdrawers"
      },
      {
        name: "paginations",
        title: "Paginations",
        component: "components/paginations"
      },
      {
        name: "typography",
        title: "Typography",
        component: "components/typography"
      }
    ]
  },
  {
    title: "Components",
    group: "uc",
    component: "components",
    icon: "ti-harddrive",
    items: [
      {
        name: "elevation",
        title: "Elevation",
        component: "components/Elevation"
      },
      {
        name: "progress",
        title: "Progress",
        component: "components/progress"
      },
      {
        name: "ratings",
        title: "Ratings",
        component: "components/ratings"
      },
      {
        name: "sheets",
        title: "Sheets",
        badge: "new",
        component: "components/sheets"
      },
      {
        name: "snackbars",
        title: "Snackbars",
        component: "components/snackbars"
      },
      {
        name: "sparklines",
        title: "Sparklines",
        component: "components/sparklines"
      },
      {
        name: "subheaders",
        title: "SubHeaders",
        component: "components/subheaders"
      },
      {
        name: "toolbars",
        title: "Toolbars",
        component: "components/toolbars"
      },
      {
        name: "tooltips",
        title: "Tooltips",
        component: "components/tooltips"
      },
      {
        name: "steppers",
        title: "Steppers",
        component: "components/steppers"
      },
      {
        name: "tabs",
        title: "Tabs",
        component: "components/tabs"
      },
      {
        name: "grid",
        title: "Grid",
        component: "components/grid"
      },
      {
        name: "windows",
        title: "Windows",
        component: "components/windows"
      }
    ]
  },
  {
    title: "Apps",
    group: "apps",
    component: "apps",
    icon: "ti-mobile",
    items: [
      {
        name: "sweetalert",
        title: "Sweet Alert",
        component: "components/sweetalert"
      },
      {
        name: "treeview",
        title: "Treeview",
        component: "components/treeview"
      },
      {
        name: "parallax",
        title: "Parallax",
        component: "components/parallax"
      },
      {
        name: "calendar",
        title: "Calendar",
        component: "components/calendar"
      },

      {
        name: "timeline",
        title: "Timeline",
        component: "components/timeline"
      },
      {
        name: "page-blank",
        title: "PageBlank",
        component: "components/page-blank"
      }
    ]
  },

  {
    group: "user",
    name: "user",
    title: "User",
    icon: "face",
    items: [
      {
        name: "profile",
        title: "Profile",
        component: "components/profile"
      },
      {
        name: "Login1",
        title: "Login1",
        component: "Login1"
      },
      {
        name: "Login2",
        title: "Login2",
        component: "Login2"
      }
    ]
  },
  {
    title: "Error",
    group: "error",
    icon: "ti-help-alt",
    items: [
      {
        name: "Error403",
        title: "Error403",
        component: "Error403"
      },
      {
        name: "Error404",
        title: "Error404",
        component: "Error404"
      },
      {
        name: "Error505",
        title: "Error505",
        component: "Error505"
      },
      {
        name: "Error503",
        title: "Error503",
        component: "Error503"
      },
      {
        name: "Error500",
        title: "Error500",
        component: "Error500"
      }
    ]
  }
];

menu.forEach(item => {
  if (item.items) {
    item.items.sort((x, personLookupResult) => {
      let textA = x.title.toUpperCase();
      let textB = personLookupResult.title.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
});
export default menu;
