// @ts-nocheck
import menu from "./menu";
var _ = require("lodash");
class core {
  constructor(o) {
    let vm = this;
    vm.menu = o.menu;

    vm.appConfig = {
      dark: false,

      //list of primary colors
      primary: [
        "#00695c",
        "#3f51b5",
        "#00695c",
        "#7986cb",
        "#00695c",
        "#9c27b0",
        "#3e2723",
        "#ef5350"
      ],

      bgIndex: 0,
      bg: "../../static/img/bg/29.jpg",

      drawer: ["Default (no property)", "Permanent", "Temporary"],
      primaryDrawer: {
        model: null,
        type: "default (no property)",
        clipped: null,
        floating: false,
        mini: false,
        drawer: true,
        drawerRight: true,
        right: null
      },
      footer: {
        inset: true
      },
      rightDrawer: false,
      expanded: true,

      snackbar: {
        show: false,
        text: "white",
        color: "pink"
      }
    };
  }

  install(Vue) {
    let vm = this;

    Vue.mixin({
      props: {
        obj: {
          type: Object,
          default() {
            return {};
          }
        }
      },
      data() {
        return {
          appConfig: vm.appConfig,
          menu: vm.menu
        };
      }
    });
  }
}

let m = new core({
  menu
});

export default m;
