export default {
  name: "zPayExpenseStatementDialog",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    debits: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: []
    },
    totalValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      confirmationCheckout: false,
      checkoutClicked: false,
      notifications: 1,
      paymentStatuses: {
        prepayment: this.$t("PAYMENT_SOLICITATION.CHARGE_TYPES.PREPAYMENTS"),
        warranty_registration: this.$t(
          "PAYMENT_SOLICITATION.CHARGE_TYPES.WARRANTY_REGISTRATION"
        ),
        separate_payment: this.$t(
          "PAYMENT_SOLICITATION.CHARGE_TYPES.SEPARETE_PAYMENT"
        ),
        no_show: this.$t("PAYMENT_SOLICITATION.CHARGE_TYPES.NO_SHOW")
      }
    };
  },
  created() {},
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  }
};
