import MainMobileLayout from "@/components/shared/Layouts/MainMobile/MainMobile.vue";

const Profile = () => import("./Profile.vue");

const UserRoutes = {
  component: MainMobileLayout,
  children: [
    {
      path: "profile",
      name: "Profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    { path: "*", redirect: "user" }
  ]
};

export default UserRoutes;
