import Vue from "vue";
import Vuetify from "vuetify";

import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  iconfont: "md" || "mdi"
});
