import PersonService from "./PersonService";
import CompanyService from "./CompanyService";
import { ApiClient, CryptoLocalStorage } from "@zoox-framework/zoox-infra";
import i18n from "@/middlewares/i18n";

export default class PaymentService {
  constructor() {
    const personService = new PersonService();
    this.companyService = new CompanyService();
    this.personId = personService.getPersonId();
    this.apiClient = new ApiClient();
  }

  async upsertSubscription(source, subscription) {
    return this.apiClient.post("open/payment/subscription", {
      source,
      subscription
    });
  }

  generatePin(orderId, chargeId = null, phone = null) {
    const company = this.companyService.getCurrentCompany();
    this.apiClient.post("payment/generatePin", {
      orderId,
      chargeId,
      personId: this.personId,
      message: `${company.name} : ${i18n.t(
        "PAYMENT_SOLICITATION.GENERATE_PIN"
      )}`,
      phone
    });
  }

  async validatePin(orderId, pin, chargeId = null) {
    const resp = await this.apiClient.post("payment/validatePin", {
      orderId,
      chargeId,
      personId: this.personId,
      pin: parseInt(pin)
    });

    return resp.validated;
  }

  async getChargeByToken(chargeToken) {
    return this.apiClient.get(`payment/customerByChargeToken/${chargeToken}`);
  }

  async updatePersonCharge(personId, chargeToken) {
    return this.apiClient.put("payment/person/charge", {
      personId,
      chargeToken
    });
  }

  async pay(orderId, chargeId, personId, payments, isClosed = false) {
    return await this.apiClient
      .post(
        "open/payment/pay",
        {
          orderId,
          chargeId,
          personId,
          isClosed,
          payments
        },
        null,
        false,
        true
      )
      .then(resp => {
        return resp;
      })
      .catch(err => {
        if (err && err.status === 422) {
          this.$toaster.error(i18n.tc("INFRA.PORTAL_CUSTOM_ERRORS.PAY_422"));
          return err;
        } else if (err) {
          err.showException = true;
          return err;
        }
      });
  }

  checkout(reservationNumber) {
    this.apiClient.post("hotel/checkout", { reservationNumber });
  }

  checkoutEmail(data) {
    this.apiClient.post("email/sendCheckoutEmail", data);
  }

  setChargeToken(chargeToken = null) {
    CryptoLocalStorage.setItem("charge_token", chargeToken);
  }

  getChargeToken() {
    return CryptoLocalStorage.getItem("charge_token") || null;
  }

  setCurrentChargeToken(chargeToken = null) {
    CryptoLocalStorage.setItem("current_charge_token", chargeToken);
  }

  getCurrentChargeToken() {
    return CryptoLocalStorage.getItem("current_charge_token") || null;
  }

  async updateCustomer(data) {
    return this.apiClient.put("open/payment/customer", data);
  }

  getStateById(id) {
    return this.apiClient.get(`open/state/${id}`);
  }

  async getCustomerByToken(token, companyId) {
    return this.apiClient.get(
      `token/customerWithoutAuth/${token}/${companyId}`
    );
  }
}
