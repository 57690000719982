// import { zPayExpenseStatementDialog } from "@zoox-framework/zoox-components";
import zPayExpenseStatementDialog from "@/components/zPayExpenseStatementDialog";
import CompanyService from "@/services/CompanyService";
import OrderService from "@/services/OrderService";
import PaymentService from "@/services/PaymentService";
import PersonService from "@/services/PersonService";
import moment from "moment";
import Vue from "vue";
import { mapActions } from "vuex";
export default {
  name: "PaymentHeader",
  components: {
    zPayExpenseStatementDialog
  },
  props: {
    debits: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      personService: new PersonService(),
      companyService: new CompanyService(),
      paymentService: new PaymentService(),
      orderService: new OrderService(),
      expenseDialog: false,
      items: [],
      totalValue: 0,
      company: {},
      loading: false,
      order: {},
      charges: [],
      show: false,
      chargeId: "",
      solicitations: true,
      pin: false,
      paymentMethod: false,
      paymentCanceled: false,
      paymentValue: 0,
      currency: "BRL",
      currentCharge: {},
      shouldShowMessage: false,
      loadingPayment: false
    };
  },
  async created() {
    var charge;
    this.loading = true;
    const $context = this;
    this.company = this.companyService.getCurrentCompany();
    let localorder = await this.orderService.getLocalOrder();
    if (!localorder) {
      const token =
        (await this.paymentService.getChargeToken()) ||
        (await this.paymentService.getChargeToken(
          await this.paymentService.setChargeToken(
            $context.$route.query.chargeToken
          )
        ));
      if (token && token != "null") {
        await this.orderService
          .getChargeByToken(token)
          .then(resp => {
            charge = resp;
          })
          .catch(err => {
            switch (err.status) {
              case 408:
                this.$toaster.error(
                  this.$t("INFRA.PORTAL_CUSTOM_ERRORS.PAY_422")
                );
                this.shouldShowMessage = true;
                this.loading = false;
                break;
              default:
                this.shouldShowMessage = true;
                this.loading = false;
                break;
            }
          });
        const orderId = charge.order.id;
        this.order = await this.orderService.getOrderById(orderId);
        $context.$store.dispatch("$setCurrentCharge", charge);
      } else {
        this.shouldShowMessage = true.valueOf;
      }
    } else {
      this.order = localorder;
    }

    await this.orderService.setLocalOrder(this.order);
    await this.loadCharges();
    if (this.shouldShowMessage == true) {
      this.$emit("showMessage");
    }
  },
  async mounted() {
    Vue.$globalEvent.$on("reload", async (reloadCharges = false) => {
      if (reloadCharges) {
        this.charges = await this.orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
      }
      this.$store.dispatch("$setIsValidated", false);
    });
  },
  computed: {
    checkinDate() {
      if (this.order) {
        return moment(this.order.metadata.checkinDate).format(
          this.$t("GLOBAL.DATE_FORMAT")
        );
      }
    },
    checkoutDate() {
      if (this.order) {
        return moment(this.order.metadata.checkoutDate).format(
          this.$t("GLOBAL.DATE_FORMAT")
        );
      }
    }
  },
  methods: {
    ...mapActions(["$setCurrentCharge", "$setIsValidated"]),
    openExpenseStatementDialog(charge) {
      this.items = charge.items;
      this.totalValue = charge.amount;
      this.expenseDialog = true;
    },
    async loadCharges() {
      if (this.order) {
        this.order.orderId = this.order.id || this.order._id;
        this.currency = this.order.currency || this.currency;

        let charges = await this.orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
        this.charges = [];
        if (charges && charges.length > 0) {
          this.charges.push(charges[0]);
        }
      }
      if (this.charges.length === 0) {
        this.shouldShowMessage = true;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    goToCompleteRegister() {
      let token;
      let tokenValidTo;
      token = this.personService.getPersonToken();
      tokenValidTo = this.personService.getTokenExpiration();
      if (token && tokenValidTo) {
        this.$router.push("/user/profile");
      } else {
        this.$emit("toLogin");
      }
    }
  }
};
