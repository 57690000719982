import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import { zLanguageChanger } from "@zoox-framework/zoox-components";
import Vue from "vue";

export default {
  name: "MainMobileLayout",
  components: {
    "z-language-changer": zLanguageChanger
  },
  data: () => ({
    drawer: null,
    personService: new PersonService(),
    companyService: new CompanyService(),
    isLoggedIn: false,
    items: [
      {
        icon: "fas fa-user",
        text: "MENU.USER.TITLE",
        to: "/",
        model: false,
        children: [
          {
            text: "GLOBAL.PROFILE",
            to: "/user/profile",
            name: "perfilUsuario"
          },
          { text: "MENU.USER.LOGOUT", to: "", action: "'logout'" }
        ]
      },
      {
        icon: "fas fa-credit-card",
        text: "MENU.PAYMENT.SOLICITATION",
        to: "/payment/solicitations",
        name: "solicitarPagamento"
      },
      {
        icon: "far fa-list-alt",
        text: "MENU.PAYMENT.HISTORY",
        to: "/payment/history",
        name: "historicoPagamento"
      }
    ],
    notifications: {
      main: 5,
      solicitarPagamento: 1,
      historicoPagamento: 1,
      extratoGastos: 2,
      editarFormasPagamento: 1
    },
    companyName: "",
    companyLogoUrl: ""
  }),
  props: {
    source: String
  },
  mounted() {
    const company = this.companyService.getCurrentCompany();
    this.companyName = company.name;
    this.companyLogoUrl = company.logo;
    this.isLoggedIn = this.personService.isAuthenticated();
  },
  methods: {
    getNumberNotification(key) {
      var arr = Object.keys(this.notifications);
      for (var i = 0; i < arr.length; i++) {
        if (key == arr[i]) {
          return Object.values(this.notifications)[i];
        }
      }
    },
    logout() {
      this.personService.logout();
      this.$router.push({ name: "LoginClient" });
    },
    reload() {
      Vue.$globalEvent.$emit("reload");
    }
  }
};
