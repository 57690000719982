export default {
  name: "PaymentCanceled",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    reloadPage() {
      location.reload();
    }
  }
};
