import ZooxPay from "@zoox-framework/pay";
import PersonService from "./PersonService";
import { ApiClient, CryptoLocalStorage } from "@zoox-framework/zoox-infra";
import queryString from "query-string";

export default class OrderService {
  constructor() {
    const personService = new PersonService();
    this.personId = personService.getPersonId();
    const accessToken = personService.getPersonToken();
    this.zooxPay = new ZooxPay({
      accessToken,
      personId: this.personId,
      baseUrl: process.env.VUE_APP_ZOOXPAY_BASE_URI
    });
    this.apiClient = new ApiClient();
  }

  async getOrder(params) {
    const response = await this.zooxPay.listOrders(params);

    if (response._count > 0) {
      return response._embedded.orders[0];
    }

    return null;
  }

  async getOrderItems({ orderId }) {
    const response = await this.zooxPay.listOrderItems({ orderId });
    return response.result;
  }

  // async getBulkItems(ids) {
  //   return this.apiClient.post(`payment/order/itemsBulk`, { ids });
  // }

  async getBulkItems(ids) {
    return this.apiClient.post(`open/payment/order/itemsBulk`, { ids });
  }

  async getAllOrders() {
    return await this.apiClient.get(`payment/order/all/${this.personId}`);
  }

  async getCharges(orderId, params) {
    const qs = queryString.stringify(params);

    this.apiClient.addHeader(
      "X-group-or-company",
      localStorage.getItem("group_or_company")
    );
    const resp = await this.apiClient.get(
      `open/payment/order/${orderId}/charges?${qs}`
    );
    return resp._embedded.charges;
  }
  // async getCharges(orderId, params) {
  //   const qs = queryString.stringify(params);
  //   const resp = await this.apiClient.get(
  //     `payment/order/${orderId}/charges?${qs}`
  //   );
  //   return resp._embedded.charges;
  // }

  // async getCharges(orderId, params) {
  //   const qs = queryString.stringify(params);
  //   const resp = await this.apiClient.get(`payment/order/${orderId}/charges?${qs}`);
  //   return resp._embedded.charges;
  // }

  async getBillingHistory(companyId, personId) {
    return this.apiClient.post(`payment/order/statement`, {
      companyId,
      personId
    });
  }

  // async getChargeByToken(token) {
  //   return this.apiClient.get(`payment/chargeByToken/${token}`);
  // }

  async getChargeByToken(token) {
    if (token == null || token === "null" || token === "") {
      return null;
    }

    return this.apiClient.get(`open/payment/chargeByToken/${token}`);
  }

  // async getOrderById(id) {
  //   return this.apiClient.get(`payment/orderById/${id}`);
  // }

  async getOrderById(id) {
    return this.apiClient.get(`open/payment/orderById/${id}`);
  }

  setLocalOrder(order = null) {
    CryptoLocalStorage.setItem("order", JSON.stringify(order));
  }

  getLocalOrder() {
    const orderStr = CryptoLocalStorage.getItem("order") || null;
    try {
      return JSON.parse(orderStr);
    } catch (ex) {
      return null;
    }
  }
}
