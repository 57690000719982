import { CryptoLocalStorage, ApiClient } from "@zoox-framework/zoox-infra";

const STORAGE_NAME = "group_or_company";
const COMPANY_STORAGE_NAME = "currentCompany";

export default class CompanyService {
  constructor() {
    this.apiClient = new ApiClient();
  }

  setGroupOrCompany(company) {
    CryptoLocalStorage.setItem(
      STORAGE_NAME,
      JSON.stringify({ type: "company", id: company.id })
    );
  }

  setCurrentCompany(company) {
    // let variavel = JSON.stringify(company);
    // let variavel2 = encodeURIComponent(variavel);
    // let variavel3 = window.btoa(variavel2);
    CryptoLocalStorage.setItem(
      COMPANY_STORAGE_NAME,
      window.btoa(
        unescape(
          encodeURIComponent(
            JSON.stringify(company)
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
        )
      )
    );
  }

  getCurrentCompany() {
    const companyStr = CryptoLocalStorage.getItem(COMPANY_STORAGE_NAME);
    try {
      let v1 = window.atob(companyStr);
      let v2 = decodeURIComponent(v1);
      return JSON.parse(v2);
    } catch (ex) {
      return null;
    }
  }

  getCurrentCompanyId() {
    const company = this.getCurrentCompany();
    if (company) {
      return company.id;
    }
    return null;
  }

  getVapIds(companyId, clientName) {
    return this.apiClient.get(`company/vapid/${companyId}/${clientName}`);
  }

  getCompanyBySubdomain(subdomain) {
    return this.apiClient.get(`company/subdomain/${subdomain}`);
  }

  getAcquirerSettings(id) {
    return this.apiClient.get(`open/company/getAcquirerParameters/${id}`);
  }

  getFnrhFormSettings(id) {
    return this.apiClient.get(`open/company/${id}/product/fnrhform`);
  }

  getCompanyCustomization(subdomain) {
    return this.apiClient.get(`company/${subdomain}/customization`);
  }
}
