import DirectPayment from "./DirectPayment/DirectPayment.vue";
import MainMobileLayout from "@/components/shared/Layouts/MainMobile/MainMobile.vue";

const PaymentHistory = () => import("./PaymentHistory/PaymentHistory.vue");
const PaymentSolicitations = () =>
  import("./PaymentSolicitations/PaymentSolicitations.vue");
const SeparatePayment = () => import("./SeparatePayment/SeparatePayment.vue");
const Checkout = () => import("./Checkout/Checkout.vue");

const PaymentRoutes = {
  component: MainMobileLayout,
  children: [
    {
      path: "history",
      name: "PaymentHistory",
      component: PaymentHistory,
      meta: { requiresAuth: true }
    },
    {
      path: "solicitations",
      name: "PaymentSolicitations",
      component: PaymentSolicitations,
      meta: { requiresAuth: true }
    },
    {
      path: "solicitations/direct",
      name: "DirectPayment",
      component: DirectPayment,
      meta: { requiresAuth: false }
    },
    {
      path: "checkout",
      name: "Checkout",
      component: Checkout,
      meta: { requiresAuth: false }
    },
    {
      path: "separate/:token",
      name: "SeparatePayment",
      component: SeparatePayment,
      meta: { requiresAuth: false }
    },
    { path: "*", redirect: "/auth" }
  ]
};

export default PaymentRoutes;
