<template>
  <div id="appRoot">
    <Loading class="zoox-loader" :active="longLoading || loading" />
    <router-view :style="companyCustomization" v-if="renderReady"></router-view>
  </div>
</template>
<!--===============================
=   end      APP          =
================================-->
<script>
import CompanyService from "./services/CompanyService";
import TermsService from "@/services/TermsService";
import { loadingMixin } from "@zoox-framework/smck-service";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "app",
  mixins: [loadingMixin],
  data() {
    return {
      delayTime: 0,
      TERMS_ENUM: {
        useTerms: 1,
        privacyTerms: 2
      },
      companyService: new CompanyService(),
      termsService: new TermsService(),
      companyCustomization: {
        "--button-color": "white",
        "--button-background-color": "#ff8f28",
        "--button-color--hover": "ff8f28",
        "--button-background-color--hover": "ff8f28",
        "--steps-color": "#white",
        "--steps-background-color": "#007dc6",
        "--v-primary-base": "#007dc6",
        "--activeSteps--background-color": "#007dc6"
      },
      renderReady: false
    };
  },
  computed: {
    ...mapState(["pushNotificationService"])
  },
  sockets: {
    connect: () => {
      // eslint-disable-next-line no-console
      console.log("socket connected");
    }
  },
  async mounted() {
    const urlFirstDot = window.location.hostname.split(".")[0] || "";

    let subdomain = urlFirstDot.replace(/^https?:\/\//, "");

    if (process.env.NODE_ENV === "development") {
      subdomain = process.env.VUE_APP_COMPANY_SUBDOMAIN;
    }

    try {
      const company = await this.companyService.getCompanyBySubdomain(
        subdomain
      );

      if (company && company.parameters_sc && company.parameters_sc.logo) {
        this.$nextTick(
          () => (Vue.prototype.$companyLogo = company.parameters_sc.logo)
        );
      }

      this.$setEnableVirtualWallet(
        // a flag é para desativar virtual wallet (por isso negado!)
        !company.parameters_sc.globalSettings.virtual_wallet
      );

      this.companyService.setCurrentCompany(company);
      this.companyService.setGroupOrCompany(company);
      if (company) {
        this.companyService
          .getCompanyCustomization(subdomain)
          .then(customization => {
            this.companyCustomization = {
              "--button-color": customization.buttonColor,
              "--button-background-color": customization.buttonColor,
              "--button-color--hover": customization.activeStepsColor,
              "--button-background-color--hover":
                customization.activeStepsColor,
              "--v-button-text-color": customization.buttonTextColor,
              "--v-primary-base": customization.stepsColor,
              "--steps-color": customization.stepsTextColor,
              "--steps-background-color": customization.stepsColor,
              "--activeSteps--background-color": customization.activeStepsColor
            };
            Vue.prototype.$customization = this.companyCustomization;
          });

        this.renderReady = true;
        await this.pushNotificationService.registerServiceWorker("sw.js");
      }
    } catch (e) {
      this.renderReady = true;
    }

    const lang = localStorage.getItem("currentLang");
    await this.getTermsByLang(lang);

    Vue.$globalEvent.$on("languageChanged", async currentLang => {
      await this.getTermsByLang(currentLang);
    });
  },
  methods: {
    ...mapActions(["$setTerms", "$setEnableVirtualWallet"]),
    async getTermsByLang(lang) {
      let terms = [];

      const taskTerms = [];
      for (const key in this.TERMS_ENUM) {
        taskTerms.push(
          this.termsService.getTermByType(this.TERMS_ENUM[key], lang)
        );
      }

      try {
        const ret = await Promise.allSettled(taskTerms);
        ret.forEach(item => {
          if (item.status === "fulfilled") {
            terms.push(item.value);
          }
        });
        this.renderReady = true;
      } catch (e) {
        this.renderReady = true;
      }

      this.$setTerms(terms);
    }
  }
};
</script>

<style lang="scss">
@import "./assets/scss/global.scss";

/* App css */
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}

.application,
.v-content {
  background: white;
}

.zoox-loader {
  position: fixed;
  top: 0;
}

.MobileNavbar img {
  margin: 5px 0px;
  max-height: 50px;
  max-width: 105px;
  vertical-align: middle;
}

.v-toolbar__content {
  height: 66px !important;
}

.v-list__tile__title {
  display: flex;
  align-items: center;
}

.v-list__tile__title img {
  margin-right: 10px;
}

.flags {
  width: 24px;
}

.vl {
  border-left: 1px solid white;
  height: 35px;
  margin: 15px;
}

.MobileNavbar .CompanyLogo {
  height: 50px;
}

.MobileNavbar .ZooxLogo {
  height: 25px;
}

.FooterLogo {
  background-color: #fff !important;
  max-height: 150px;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.FooterLogo label {
  color: #d6c7c7;
  font-size: 0.8rem;
  margin: 5px;
}

.v-content {
  padding: 55px 0px 0 !important;
}

// .v-content .v-content__wrap{
//   margin: 10px !important;
// }

.FooterLogo img {
  vertical-align: middle;
  max-width: 150px !important;
}

.Split .Split-content .Split-card {
  margin: 0 auto;
}

.application--wrap {
  min-height: 92vh;
}

/* The Grid */

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to .content-wrapper-before {
  position: absolute;
  z-index: -1;
  top: 64px;
  left: 0;
  width: 100%;

  -webkit-transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

/* Animation */
@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

v-layout {
  margin-left: 5px;
}
.container.grid-list-xl .layout .flex {
  padding: 2px;
}

.v-content__wrap {
  display: flex !important;
  vertical-align: middle !important;
}

// // CORREÇÃO NOS INPUT'S [ERROR] //
.CentralPaySelectbox,
.CentralPayFormField:not(.z-flag-number-layout) {
  height: 75px !important;
}

.CentralPayFormField {
  margin-bottom: 15px !important;
  &.z-flag-number-layout {
    .z-flag-phone-ddi,
    .z-flag-phone-number {
      padding-top: 0 !important;

      &.error--text {
        .v-input__slot {
          border: 1px solid red;

          label {
            color: red !important;
          }
        }
      }
    }
  }

  .v-input__control {
    .v-input__slot {
      min-height: 53px;
      label.error--text {
        color: red !important;
      }
    }

    .v-messages__message {
      margin-left: 0.2rem;
      text-align: left;
    }
  }
}

.client-auth-card {
  .OpenCentralPaySelectBox {
    .v-input__control {
      .v-input__slot {
        .v-text-field__details {
          padding-left: 0;
        }

        label.error--text {
          color: red !important;
        }
      }

      .v-messages__message {
        text-align: left;
      }
    }
  }

  button#loginBtn {
    margin-top: 1rem !important;
  }
}

.client-auth-content {
  button#zPayForgotPasswordClientSubmitForm {
    margin-top: 1rem !important;
  }
}
</style>
