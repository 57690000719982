const Auth = () => import("./Auth.vue");
const Login = () => import("./Login/Login.vue");
const RegisterClient = () => import("./RegisterClient/RegisterClient.vue");
const ForgotPassword = () => import("./ForgotPassword/ForgotPassword.vue");
const NewPassword = () => import("./NewPassword/NewPassword.vue");
const SmsMesage = () => import("./SmsMessage/SmsMessage.vue");

const ClientAuthRoutes = {
  component: Auth,
  children: [
    {
      path: "",
      name: "LoginClient",
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: "forgot-password",
      name: "ForgotPasswordClient",
      component: ForgotPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "register-client",
      name: "RegisterClient",
      component: RegisterClient,
      meta: { requiresAuth: false }
    },
    {
      path: "new-password",
      name: "NewPasswordClient",
      component: NewPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "sms-message",
      name: "SmsMessageClient",
      component: SmsMesage,
      meta: { requiresAuth: false }
    },
    { path: "*", redirect: "/auth" }
  ]
};

export default ClientAuthRoutes;
