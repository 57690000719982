import Vue from "vue";
import { zPayPinForm } from "@zoox-framework/zoox-components";
import zPayExpenseStatementDialog from "@/components/zPayExpenseStatementDialog";
import zPayCreditCard from "@/components/zPayCreditCard";
import CompanyService from "@/services/CompanyService";
import OrderService from "@/services/OrderService";
import PaymentHeader from "@/components/PaymentHeader/PaymentHeader.vue";
import PaymentService from "@/services/PaymentService";
import PersonService from "@/services/PersonService";
import PaymentCanceled from "@/components/PaymentCanceled/PaymentCanceled.vue";
import { SubscriptionService } from "@zoox-framework/messenger-helper";
import CardService from "@/services/CardService";
import moment from "moment";
import { mapActions } from "vuex";
import i18n from "@/middlewares/i18n";
export default {
  name: "DirectPayment",
  components: {
    "z-pay-pin-form": zPayPinForm,
    PaymentCanceled: PaymentCanceled,
    PaymentHeader,
    zPayCreditCard,
    zPayExpenseStatementDialog
  },
  data() {
    return {
      cardService: new CardService(),
      personService: new PersonService(),
      companyService: new CompanyService(),
      orderService: new OrderService(),
      paymentService: new PaymentService(),
      pushNotification: new SubscriptionService(),
      company: {},
      order: {},
      charges: [],
      step: 0,
      debits: [],
      items: [],
      totalValue: 0,
      chargeId: "",
      solicitations: true,
      pin: false,
      paymentMethod: false,
      paymentCanceled: false,
      paymentValue: 0,
      currency: "BRL",
      checkinDate: "",
      checkoutDate: "",
      currentCharge: {},
      shouldShowMessage: false,
      loadingPayment: false,
      dialogExpenseVisible: false
    };
  },
  async mounted() {
    const orderService = new OrderService();
    const token = await this.paymentService.getChargeToken();

    if (token) {
      this.currentCharge = await orderService.getChargeByToken(token);
      this.cardService = new CardService(this.currentCharge.companyId, token);
    }
    Vue.$globalEvent.$on("reload", async (reloadCharges = false) => {
      if (reloadCharges) {
        this.charges = await this.orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
      }
      this.showLoading = false;
      this.solicitations = true;
      this.pin = false;
      this.paymentMethod = false;
      this.paymentCanceled = false;
      this.loadingPayment = false;
      this.$store.dispatch("$setIsValidated", false);
    });

    const $context = this;
    if ($context.$store.state.isValidated === true) {
      this.solicitations = false;
      this.pin = false;
      this.paymentMethod = true;
      this.currentCharge = $context.$store.state.currentCharge;
      this.paymentValue = $context.$store.state.paymentValue;
    } else {
      this.company = this.companyService.getCurrentCompany();
      this.order = await orderService.getLocalOrder();
      if (this.order != null) {
        this.order.orderId = this.order.id || this.order._id;
        this.currency = this.order.currency || this.currency;
        this.charges = await orderService.getCharges(this.order.orderId, {
          status: "pending"
        });
        if (this.charges && this.charges.length > 0) {
          this.paymentValue = this.charges[0].amount;
          let itemsIds = [];
          this.charges[0].items.forEach(function(item) {
            itemsIds.push(item.id);
          });
          const items = await this.orderService.getBulkItems(itemsIds);
          items.forEach(item => {
            const percentLeft = item.percentLeft || 100;
            const itemValue = (item.amount * item.quantity * percentLeft) / 100;
            this.debits.push({
              name: item.description,
              price: itemValue
            });
          });
        }
        this.checkinDate = moment
          .utc(this.order.metadata.checkinDate)
          .endOf("day")
          .format(this.$t("GLOBAL.DATE_FORMAT"));
        this.checkoutDate = moment
          .utc(this.order.metadata.checkoutDate)
          .endOf("day")
          .format(this.$t("GLOBAL.DATE_FORMAT"));
        if (this.charges.length === 0) {
          this.shouldShowMessage = true;
        }
      } else {
        this.shouldShowMessage = true;
      }
    }
  },
  methods: {
    ...mapActions(["$setCurrentCharge", "$setIsValidated"]),
    openViewStatement(obj) {
      this.value = obj.amount;
      this.debits = obj.debits;
      this.dialogExpenseVisible = true;
    },
    getGeolocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(({ coords }) =>
            resolve(coords)
          );
        } else {
          reject("Geolocation services are not supported by your browser");
        }
      });
    },

    async Pay(card) {
      const cardCustomer = card.customer;
      const installments = card.paymentIterations ? card.paymentIterations : 1;
      delete card.paymentIterations;
      delete card.customer;
      let cardToken;
      await this.cardService
        .createCardToken(card)
        .then(resp => {
          cardToken = resp;
          Vue.$globalEvent.$emit("changePayLoadingButton", false);
        })
        .catch(() => {
          Vue.$globalEvent.$emit("changePayLoadingButton", false);
        });
      if (cardToken.status === 422) {
        this.$toaster.error(i18n.tc("INFRA.PORTAL_CUSTOM_ERRORS.CARD_422"));
      } else {
        const newCharge = { ...this.currentCharge };
        let chargesToPay = [];

        let operationType = "auth_and_capture";
        if (newCharge.metadata.type === "warranty_registration") {
          operationType = "auth_only";
        }

        if (!newCharge.customer) {
          newCharge.customer = {
            name: card.holderName
          };
        }

        newCharge.customer.documentTypeId = cardCustomer.documentTypeId;
        newCharge.customer.documentNumber = cardCustomer.documentNumber;
        newCharge.customer.standalone = true;
        newCharge.customer.address = {
          line_1: card.billingAddress.line1,
          line_2: card.billingAddress.line2,
          zip_code: card.billingAddress.zipCode,
          city: card.billingAddress.city,
          state: card.billingAddress.state,
          country: card.billingAddress.country
        };

        newCharge.method = "creditCard";
        newCharge.creditCard = {
          cardToken: cardToken.data.id,
          operation: operationType,
          installments: installments
          // installments:
          //   card.acquirer.acceptInstallments &&
          //   newCharge.metadata.type !== "warranty_registration"
          //     ? card.paymentIterations.value || card.paymentIterations || 1
          //     : 1
        };

        newCharge.metadata = {
          chargeId: newCharge.id
        };
        chargesToPay.push(newCharge);

        try {
          const resp = await this.paymentService.pay(
            this.currentCharge.order.id,
            this.currentCharge.id,
            null,
            chargesToPay
          );
          if (resp.status == "paid" || resp.status == "pending") {
            this.successPayment();
          }
          if (resp.status == "failed" || resp.status == "cancelled") {
            this.successPayment();
          }
        } catch (err) {
          Vue.$globalEvent.$emit("changePayLoadingButton", false);
          if (err.body && err.body.message) {
            this.$toaster.error(err.body.message);
          } else {
            this.$toaster.error(this.$t("GLOBAL.ERROR"));
          }
        }

        // this.card = card
        // let cardToken = cardService.createCardToken(this.card);
        // console.log(cardToken);
      }
    },
    successPayment() {
      const $context = this;
      Vue.$globalEvent.$emit("changePayLoadingButton", false);
      $context.$setCurrentCharge(this.currentCharge);
      $context.$setIsValidated(true);
      $context.$router.push("/payment/checkout");
    },
    ValidatePin(validated) {
      const $context = this;
      if (validated === true) {
        $context.$store.dispatch("$setCurrentCharge", this.currentCharge);
        $context.$store.dispatch("$setPaymentValue", this.paymentValue);
        $context.$store.dispatch("$setIsValidated", true);
        this.pin = false;
        this.paymentMethod = true;
      } else {
        this.$toaster.error(this.$t("PAYMENT_SOLICITATION.PIN_NOT_VALID"));
      }
    },
    async submitPin({ orderId, pinNumber, chargeId }) {
      const resp = await this.paymentService.validatePin(
        orderId,
        pinNumber,
        chargeId
      );
      this.ValidatePin(resp);
    },
    async forgotPin({ orderId, chargeId }) {
      await this.paymentService.generatePin(orderId, chargeId);
      this.$toaster.success(this.$t("PAYMENT_SOLICITATION.PIN_GENERATED"));
    },
    backToAuth() {
      const token = this.paymentService.getChargeToken();
      if (token) {
        this.$router.push(`/auth?chargeToken=${token}`);
      } else {
        this.$router.push("/auth");
      }
    }
  }
};
